<template src="./logout.htm">
    
</template>

<script>
import Form from '@/utils/form'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            show1: false,
            form: new Form({
                email: '',
                password: ''
            })
        }
    },
    computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
    methods: {
        signIn() {
            this.$router.push('/login'); 
        },
    },
    watch: {
        'form.email'() {
            this.form.$clearError('username')
        },
        'form.password'() {
            this.form.$clearError('password')
        }
    },
    mounted() {
         if (this.isAuthenticated) {
             this.$router.replace({ name: 'auth.login' })
         }
    },
}
</script>